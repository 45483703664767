import { TSpecification } from '../_types'

const derived_specification: TSpecification = {
    market: 'derived',
    data: [
        {
            instrument: {
                symbol: 'BOOM1000NEW',
                instrument: 'Boom 1000 Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.2,
            minimum_spread: 0.125,
            target_spread: 0.1271,
            max_effective_leverage: 400,
            margin_requirement: 0.25,
            swap_long: -0.0194,
            swap_short: -0.0194,
            trading_hours: 'Mon 00:00 - Sun 24:00 GMT',
        },
        {
            instrument: {
                symbol: 'VBOOMIC300',
                instrument: 'Boom 300 Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.1,
            minimum_spread: 0.022,
            target_spread: 0.024,
            max_effective_leverage: 100,
            margin_requirement: 1.0,
            swap_long: -0.0833,
            swap_short: -0.0833,
            trading_hours: 'Mon 00:00 - Sun 24:00 GMT',
        },
        {
            instrument: {
                symbol: 'BOOM500NEW',
                instrument: 'Boom 500 Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.2,
            minimum_spread: 0.089,
            target_spread: 0.09,
            max_effective_leverage: 400,
            margin_requirement: 0.25,
            swap_long: -0.0278,
            swap_short: -0.0278,
            trading_hours: 'Mon 00:00 - Sun 24:00 GMT',
        },
        {
            instrument: {
                symbol: 'CRASH1000NEW',
                instrument: 'Crash 1000 Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.2,
            minimum_spread: 0.088,
            target_spread: 0.0897,
            max_effective_leverage: 400,
            margin_requirement: 0.25,
            swap_long: -0.0194,
            swap_short: -0.0194,
            trading_hours: 'Mon 00:00 - Sun 24:00 GMT',
        },
        {
            instrument: {
                symbol: 'VCRASHIC300',
                instrument: 'Crash 300 Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.05,
            minimum_spread: 0.221,
            target_spread: 0.243,
            max_effective_leverage: 100,
            margin_requirement: 1.0,
            swap_long: -0.0833,
            swap_short: -0.0833,
            trading_hours: 'Mon 00:00 - Sun 24:00 GMT',
        },
        {
            instrument: {
                symbol: 'CRASH500NEW',
                instrument: 'Crash 500 Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.2,
            minimum_spread: 0.102,
            target_spread: 0.103,
            max_effective_leverage: 400,
            margin_requirement: 0.25,
            swap_long: -0.0278,
            swap_short: -0.0278,
            trading_hours: 'Mon 00:00 - Sun 24:00 GMT',
        },
        {
            instrument: {
                symbol: 'JUMP10NEW',
                swf_icon: true,
                instrument: 'Jump 10 Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 2.03,
            target_spread: 2.05,
            max_effective_leverage: 2000,
            margin_requirement: 0.05,
            swap_long: -0.0028,
            swap_short: -0.0028,
            trading_hours: 'Mon 00:00 - Sun 24:00 GMT',
        },
        {
            instrument: {
                symbol: 'JUMP100NEW',
                swf_icon: true,
                instrument: 'Jump 100 Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 0.72,
            target_spread: 0.78,
            max_effective_leverage: 400,
            margin_requirement: 0.25,
            swap_long: -0.0278,
            swap_short: -0.0278,
            trading_hours: 'Mon 00:00 - Sun 24:00 GMT',
        },
        {
            instrument: {
                symbol: 'JUMP25NEW',
                swf_icon: true,
                instrument: 'Jump 25 Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 5.71,
            target_spread: 5.9,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: -0.0069,
            swap_short: -0.0069,
            trading_hours: 'Mon 00:00 - Sun 24:00 GMT',
        },
        {
            instrument: {
                symbol: 'JUMP50NEW',
                swf_icon: true,
                instrument: 'Jump 50 Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 3.66,
            target_spread: 3.88,
            max_effective_leverage: 500,
            margin_requirement: 0.2,
            swap_long: -0.0139,
            swap_short: -0.0139,
            trading_hours: 'Mon 00:00 - Sun 24:00 GMT',
        },
        {
            instrument: {
                symbol: 'JUMP75NEW',
                swf_icon: true,
                instrument: 'Jump 75 Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 1.35,
            target_spread: 1.45,
            max_effective_leverage: 400,
            margin_requirement: 0.25,
            swap_long: -0.0208,
            swap_short: -0.0208,
            trading_hours: 'Mon 00:00 - Sun 24:00 GMT',
        },
        {
            instrument: {
                symbol: 'RANGEBREAK100New',
                swf_icon: true,
                instrument: 'Range Break 100 Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 1,
            target_spread: 1,
            max_effective_leverage: '-',
            margin_requirement: '400 USD margin per lot',
            swap_long: -30,
            swap_short: -30,
            trading_hours: 'Mon 00:00 - Sun 24:00 GMT',
        },
        {
            instrument: {
                symbol: 'RANGEBREAK200New',
                swf_icon: true,
                instrument: 'Range Break 200 Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 1,
            target_spread: 1,
            max_effective_leverage: '-',
            margin_requirement: '800 USD margin per lot',
            swap_long: -30,
            swap_short: -30,
            trading_hours: 'Mon 00:00 - Sun 24:00 GMT',
        },
        {
            instrument: {
                symbol: 'STEPINDICES',
                swf_icon: true,
                instrument: 'Step Index',
            },
            contract_size: 10,
            base_currency: 'USD',
            minimum_size: 0.1,
            minimum_spread: 0.1,
            target_spread: 0.1,
            max_effective_leverage: '-',
            margin_requirement: '25 USD margin per lot',
            swap_long: -0.0015,
            swap_short: -0.0015,
            trading_hours: 'Mon 00:00 - Sun 24:00 GMT',
        },
        {
            instrument: {
                symbol: 'INDEX10S1NEW',
                swf_icon: true,
                instrument: 'Volatility 10 (1s) Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.2,
            minimum_spread: 0.2,
            target_spread: 0.2,
            max_effective_leverage: 4000,
            margin_requirement: 0.025,
            swap_long: -0.0028,
            swap_short: -0.0028,
            trading_hours: 'Mon 00:00 - Sun 24:00 GMT',
        },
        {
            instrument: {
                symbol: 'INDEX10NEW',
                swf_icon: true,
                instrument: 'Volatility 10 Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.3,
            minimum_spread: 0.149,
            target_spread: 0.149,
            max_effective_leverage: 4000,
            margin_requirement: 0.025,
            swap_long: -0.0028,
            swap_short: -0.0028,
            trading_hours: 'Mon 00:00 - Sun 24:00 GMT',
        },
        {
            instrument: {
                symbol: 'INDEX100S1NEW',
                swf_icon: true,
                instrument: 'Volatility 100 (1s) Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.1,
            minimum_spread: 0.24,
            target_spread: 0.24,
            max_effective_leverage: 2000,
            margin_requirement: 0.05,
            swap_long: -0.0278,
            swap_short: -0.0278,
            trading_hours: 'Mon 00:00 - Sun 24:00 GMT',
        },
        {
            instrument: {
                symbol: 'INDEX100NEW',
                swf_icon: true,
                instrument: 'Volatility 100 Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.2,
            minimum_spread: 1.46,
            target_spread: 1.46,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: -0.0278,
            swap_short: -0.0278,
            trading_hours: 'Mon 00:00 - Sun 24:00 GMT',
        },
        {
            instrument: {
                symbol: 'VINDEX2001S',
                swf_icon: true,
                instrument: 'Volatility 200 (1s) Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.02,
            minimum_spread: 0.01,
            target_spread: 0.01,
            max_effective_leverage: 300,
            margin_requirement: 0.333,
            swap_long: -0.0556,
            swap_short: -0.0556,
            trading_hours: 'Mon 00:00 - Sun 24:00 GMT',
        },
        {
            instrument: {
                symbol: 'INDEX25S1NEW',
                swf_icon: true,
                instrument: 'Volatility 25 (1s) Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.005,
            minimum_spread: 29.02,
            target_spread: 29.02,
            max_effective_leverage: 4000,
            margin_requirement: 0.025,
            swap_long: -0.0069,
            swap_short: -0.0069,
            trading_hours: 'Mon 00:00 - Sun 24:00 GMT',
        },
        {
            instrument: {
                symbol: 'INDEX25NEW',
                swf_icon: true,
                instrument: 'Volatility 25 Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.5,
            minimum_spread: 0.109,
            target_spread: 0.109,
            max_effective_leverage: 3000,
            margin_requirement: 0.033,
            swap_long: -0.0069,
            swap_short: -0.0069,
            trading_hours: 'Mon 00:00 - Sun 24:00 GMT',
        },
        {
            instrument: {
                symbol: 'VINDEX3001S',
                swf_icon: true,
                instrument: 'Volatility 300 (1s) Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 1,
            minimum_spread: 0.01,
            target_spread: 0.01,
            max_effective_leverage: 200,
            margin_requirement: 0.5,
            swap_long: -0.0833,
            swap_short: -0.0833,
            trading_hours: 'Mon 00:00 - Sun 24:00 GMT',
        },
        {
            instrument: {
                symbol: 'INDEX50S1NEW',
                swf_icon: true,
                instrument: 'Volatility 50 (1s) Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.005,
            minimum_spread: 78.41,
            target_spread: 78.41,
            max_effective_leverage: 3000,
            margin_requirement: 0.033,
            swap_long: -0.0139,
            swap_short: -0.0139,
            trading_hours: 'Mon 00:00 - Sun 24:00 GMT',
        },
        {
            instrument: {
                symbol: 'INDEX50NEW',
                swf_icon: true,
                instrument: 'Volatility 50 Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 3,
            minimum_spread: 0.022,
            target_spread: 0.022,
            max_effective_leverage: 2000,
            margin_requirement: 0.05,
            swap_long: -0.0139,
            swap_short: -0.0139,
            trading_hours: 'Mon 00:00 - Sun 24:00 GMT',
        },
        {
            instrument: {
                symbol: 'INDEX75S1NEW',
                swf_icon: true,
                instrument: 'Volatility 75 (1s) Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.005,
            minimum_spread: 1.22,
            target_spread: 1.22,
            max_effective_leverage: 2000,
            margin_requirement: 0.05,
            swap_long: -0.0208,
            swap_short: -0.0208,
            trading_hours: 'Mon 00:00 - Sun 24:00 GMT',
        },
        {
            instrument: {
                symbol: 'INDEX75NEW',
                swf_icon: true,
                instrument: 'Volatility 75 Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.001,
            minimum_spread: 89,
            target_spread: 89,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: -0.0208,
            swap_short: -0.0208,
            trading_hours: 'Mon 00:00 - Sun 24:00 GMT',
        },
        {
            instrument: {
                symbol: 'VINDEX1501S',
                swf_icon: true,
                instrument: 'Volatility 150 (1s) Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.001,
            minimum_spread: 44.83,
            target_spread: 44.83,
            max_effective_leverage: 400,
            margin_requirement: 0.25,
            swap_long: -0.0416,
            swap_short: -0.0416,
            trading_hours: 'Mon 00:00 - Sun 24:00 GMT',
        },
        {
            instrument: {
                symbol: 'VINDEX2501S',
                swf_icon: true,
                instrument: 'Volatility 250 (1s) Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.001,
            minimum_spread: 61.15,
            target_spread: 61.15,
            max_effective_leverage: 250,
            margin_requirement: 0.4,
            swap_long: -0.0694,
            swap_short: -0.0694,
            trading_hours: 'Mon 00:00 - Sun 24:00 GMT',
        },
        {
            instrument: {
                symbol: 'AUDUSD10',
                instrument: 'AUDUSD DFX 10 Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 7.54,
            target_spread: 8.82,
            max_effective_leverage: 500,
            margin_requirement: 0.2,
            swap_long: -0.0028,
            swap_short: -0.0028,
            trading_hours: 'Sun 22:05 - Friday 21:55 GMT\nDaily Break: 22:00-22:05',
        },
        {
            instrument: {
                symbol: 'EURUSD10',
                instrument: 'EURUSD DFX 10 Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 8.51,
            target_spread: 9.89,
            max_effective_leverage: 500,
            margin_requirement: 0.2,
            swap_long: -0.0028,
            swap_short: -0.0028,
            trading_hours: 'Sun 22:05 - Friday 21:55 GMT\nDaily Break: 22:00-22:05',
        },
        {
            instrument: {
                symbol: 'GBPUSD10',
                instrument: 'GBPUSD DFX 10 Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 8.25,
            target_spread: 9.75,
            max_effective_leverage: 500,
            margin_requirement: 0.2,
            swap_long: -0.0028,
            swap_short: -0.0028,
            trading_hours: 'Sun 22:05 - Friday 21:55 GMT\nDaily Break: 22:00-22:05',
        },
        {
            instrument: {
                symbol: 'USDCHF10',
                instrument: 'USDCHF DFX 10 Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 8.76,
            target_spread: 10.15,
            max_effective_leverage: 500,
            margin_requirement: 0.2,
            swap_long: -0.0028,
            swap_short: -0.0028,
            trading_hours: 'Sun 22:05 - Friday 21:55 GMT\nDaily Break: 22:00-22:05',
        },
        {
            instrument: {
                symbol: 'USDJPY10',
                instrument: 'USDJPY DFX 10 Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 12.55,
            target_spread: 14.89,
            max_effective_leverage: 500,
            margin_requirement: 0.2,
            swap_long: -0.0028,
            swap_short: -0.0028,
            trading_hours: 'Sun 22:05 - Friday 21:55 GMT\nDaily Break: 22:00-22:05',
        },
        {
            instrument: {
                symbol: 'AUDBASKET',
                instrument: 'AUD Basket',
            },
            contract_size: 100,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 0.036,
            target_spread: 0.072,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: -0.0028,
            swap_short: -0.0028,
            trading_hours: 'Mon 00:00 - Fri 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'EURBASKET',
                instrument: 'EUR Basket',
            },
            contract_size: 100,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 0.038,
            target_spread: 0.074,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: -0.0028,
            swap_short: -0.0028,
            trading_hours: 'Mon 00:00 - Fri 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'GBPBASKET',
                instrument: 'GBP Basket',
            },
            contract_size: 100,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 0.032,
            target_spread: 0.064,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: -0.0028,
            swap_short: -0.0028,
            trading_hours: 'Mon 00:00 - Fri 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'GOLDBASKET',
                instrument: 'Gold Basket',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 94.348,
            target_spread: 114.361,
            max_effective_leverage: 200,
            margin_requirement: 0.5,
            swap_long: -0.0003,
            swap_short: -0.0003,
            trading_hours: 'Mon 00:00 - Fri 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'USDBASKET',
                instrument: 'USD Basket',
            },
            contract_size: 100,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 0.034,
            target_spread: 0.068,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: -0.0028,
            swap_short: -0.0028,
            trading_hours: 'Mon 00:00 - Fri 20:55 GMT',
        },
    ],
    eu_data: [
        {
            instrument: {
                symbol: 'BOOM300EU',
                instrument: 'Boom 300 Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.1,
            minimum_spread: 0.022,
            target_spread: 0.024,
            max_effective_leverage: 5,
            margin_requirement: 20.0,
            swap_long: -0.08,
            swap_short: -0.08,
            trading_hours: 'Mon 00:00 - Sun 24:00 GMT',
        },
        {
            instrument: {
                symbol: 'CRASH300EU',
                instrument: 'Crash 300 Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.1,
            minimum_spread: 0.221,
            target_spread: 0.243,
            max_effective_leverage: 5,
            margin_requirement: 20.0,
            swap_long: -0.08,
            swap_short: -0.08,
            trading_hours: 'Mon 00:00 - Sun 24:00 GMT',
        },
        {
            instrument: {
                symbol: 'VINDEX1501S',
                instrument: 'Volatility 150 (1s) Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.001,
            minimum_spread: 44.83,
            target_spread: 44.83,
            max_effective_leverage: 5,
            margin_requirement: 20.0,
            swap_long: -0.0416,
            swap_short: -0.0416,
            trading_hours: 'Mon 00:00 - Sun 24:00 GMT',
        },
        {
            instrument: {
                symbol: 'VINDEX2501S',
                instrument: 'Volatility 250 (1s) Index',
            },
            contract_size: 1,
            base_currency: 'USD',
            minimum_size: 0.001,
            minimum_spread: 61.15,
            target_spread: 61.15,
            max_effective_leverage: 5,
            margin_requirement: 20.0,
            swap_long: -0.0694,
            swap_short: -0.0694,
            trading_hours: 'Mon 00:00 - Sun 24:00 GMT',
        },
    ],
}
export default derived_specification
