import { TSpecification } from '../_types'

const forex_specification: TSpecification = {
    market: 'forex',
    dl_title: '_t_Dynamic tiers ⁠— Forex majors_t_',
    dl_data: [
        {
            from: 0.01,
            to: 1,
            leverage: '1,500',
        },
        {
            from: 1.01,
            to: 5,
            leverage: '1,000',
        },
        {
            from: 5.01,
            to: 10,
            leverage: 500,
        },
        {
            from: 10.01,
            to: 15,
            leverage: 100,
        },
    ],
    data: [
        {
            instrument: {
                symbol: 'AUDJPY',
                dl_icon: true,
                swf_icon: true,
                instrument: 'AUD/JPY',
            },
            contract_size: 100000,
            base_currency: 'AUD',
            minimum_size: 0.01,
            minimum_spread: 0.011,
            target_spread: 0.015,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'AUDUSD',
                dl_icon: true,
                swf_icon: true,
                instrument: 'AUD/USD',
            },
            contract_size: 100000,
            base_currency: 'AUD',
            minimum_size: 0.01,
            minimum_spread: 0.00003,
            target_spread: 0.00004,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'EURAUD',
                dl_icon: true,
                swf_icon: true,
                instrument: 'EUR/AUD',
            },
            contract_size: 100000,
            base_currency: 'EUR',
            minimum_size: 0.01,
            minimum_spread: 0.00006,
            target_spread: 0.00015,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'EURCAD',
                dl_icon: true,
                swf_icon: true,
                instrument: 'EUR/CAD',
            },
            contract_size: 100000,
            base_currency: 'EUR',
            minimum_size: 0.01,
            minimum_spread: 0.00006,
            target_spread: 0.00016,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'EURCHF',
                dl_icon: true,
                swf_icon: true,
                instrument: 'EUR/CHF',
            },
            contract_size: 100000,
            base_currency: 'EUR',
            minimum_size: 0.01,
            minimum_spread: 0.00003,
            target_spread: 0.00006,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'EURGBP',
                dl_icon: true,
                swf_icon: true,
                instrument: 'EUR/GBP',
            },
            contract_size: 100000,
            base_currency: 'EUR',
            minimum_size: 0.01,
            minimum_spread: 0.00003,
            target_spread: 0.00005,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'EURJPY',
                dl_icon: true,
                swf_icon: true,
                instrument: 'EUR/JPY',
            },
            contract_size: 100000,
            base_currency: 'EUR',
            minimum_size: 0.01,
            minimum_spread: 0.005,
            target_spread: 0.01,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'EURUSD',
                dl_icon: true,
                swf_icon: true,
                instrument: 'EUR/USD',
            },
            contract_size: 100000,
            base_currency: 'EUR',
            minimum_size: 0.01,
            minimum_spread: 0.00004,
            target_spread: 0.00005,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'GBPAUD',
                dl_icon: true,
                swf_icon: true,
                instrument: 'GBP/AUD',
            },
            contract_size: 100000,
            base_currency: 'GBP',
            minimum_size: 0.01,
            minimum_spread: 0.00005,
            target_spread: 0.00021,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'GBPJPY',
                dl_icon: true,
                swf_icon: true,
                instrument: 'GBP/JPY',
            },
            contract_size: 100000,
            base_currency: 'GBP',
            minimum_size: 0.01,
            minimum_spread: 0.005,
            target_spread: 0.014,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'GBPUSD',
                dl_icon: true,
                swf_icon: true,
                instrument: 'GBP/USD',
            },
            contract_size: 100000,
            base_currency: 'GBP',
            minimum_size: 0.01,
            minimum_spread: 0.00004,
            target_spread: 0.00008,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'USDCAD',
                dl_icon: true,
                swf_icon: true,
                instrument: 'USD/CAD',
            },
            contract_size: 100000,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 0.00006,
            target_spread: 0.00011,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'USDCHF',
                dl_icon: true,
                swf_icon: true,
                instrument: 'USD/CHF',
            },
            contract_size: 100000,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 0.00003,
            target_spread: 0.00007,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'USDJPY',
                dl_icon: true,
                swf_icon: true,
                instrument: 'USD/JPY',
            },
            contract_size: 100000,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 0.004,
            target_spread: 0.009,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'AUDCAD',
                swf_icon: true,
                instrument: 'AUD/CAD',
            },
            contract_size: 100000,
            base_currency: 'AUD',
            minimum_size: 0.01,
            minimum_spread: 0.0001,
            target_spread: 0.00016,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'AUDCHF',
                swf_icon: true,
                instrument: 'AUD/CHF',
            },
            contract_size: 100000,
            base_currency: 'AUD',
            minimum_size: 0.01,
            minimum_spread: 0.00008,
            target_spread: 0.00013,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'AUDNZD',
                swf_icon: true,
                instrument: 'AUD/NZD',
            },
            contract_size: 100000,
            base_currency: 'AUD',
            minimum_size: 0.01,
            minimum_spread: 0.00011,
            target_spread: 0.00021,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'CADCHF',
                swf_icon: true,
                instrument: 'CAD/CHF',
            },
            contract_size: 100000,
            base_currency: 'CAD',
            minimum_size: 0.01,
            minimum_spread: 0.00008,
            target_spread: 0.00013,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 3.15,
            swap_short: -8.62,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'CADJPY',
                swf_icon: true,
                instrument: 'CAD/JPY',
            },
            contract_size: 100000,
            base_currency: 'CAD',
            minimum_size: 0.01,
            minimum_spread: 0.01,
            target_spread: 0.017,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 12.74,
            swap_short: -15.02,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'CHFJPY',
                swf_icon: true,
                instrument: 'CHF/JPY',
            },
            contract_size: 100000,
            base_currency: 'CHF',
            minimum_size: 0.01,
            minimum_spread: 0.01,
            target_spread: 0.021,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: -0.02,
            swap_short: -5.8,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'EURNOK',
                swf_icon: true,
                instrument: 'EUR/NOK',
            },
            contract_size: 100000,
            base_currency: 'EUR',
            minimum_size: 0.01,
            minimum_spread: 0.00046,
            target_spread: 0.00385,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: -18.65,
            swap_short: -18.4,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'EURNZD',
                swf_icon: true,
                instrument: 'EUR/NZD',
            },
            contract_size: 100000,
            base_currency: 'EUR',
            minimum_size: 0.01,
            minimum_spread: 0.00008,
            target_spread: 0.00024,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'EURPLN',
                swf_icon: true,
                instrument: 'EUR/PLN',
            },
            contract_size: 100000,
            base_currency: 'EUR',
            minimum_size: 0.01,
            minimum_spread: 0.00058,
            target_spread: 0.00125,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: -70.36,
            swap_short: 43.55,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'EURSEK',
                swf_icon: true,
                instrument: 'EUR/SEK',
            },
            contract_size: 100000,
            base_currency: 'EUR',
            minimum_size: 0.01,
            minimum_spread: 0.00135,
            target_spread: 0.00411,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: -42.307,
            swap_short: -29.695,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'GBPCAD',
                swf_icon: true,
                instrument: 'GBP/CAD',
            },
            contract_size: 100000,
            base_currency: 'GBP',
            minimum_size: 0.01,
            minimum_spread: 0.00011,
            target_spread: 0.00025,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: -5.21,
            swap_short: -1.53,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'GBPCHF',
                swf_icon: true,
                instrument: 'GBP/CHF',
            },
            contract_size: 100000,
            base_currency: 'GBP',
            minimum_size: 0.01,
            minimum_spread: 0.00009,
            target_spread: 0.0002,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'GBPNOK',
                swf_icon: true,
                instrument: 'GBP/NOK',
            },
            contract_size: 100000,
            base_currency: 'GBP',
            minimum_size: 0.01,
            minimum_spread: 0.00076,
            target_spread: 0.00477,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 10.98,
            swap_short: -70.94,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'GBPNZD',
                swf_icon: true,
                instrument: 'GBP/NZD',
            },
            contract_size: 100000,
            base_currency: 'GBP',
            minimum_size: 0.01,
            minimum_spread: 0.00016,
            target_spread: 0.00039,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: -12.671,
            swap_short: -3.03,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'GBPSEK',
                swf_icon: true,
                instrument: 'GBP/SEK',
            },
            contract_size: 100000,
            base_currency: 'GBP',
            minimum_size: 0.01,
            minimum_spread: 0.00155,
            target_spread: 0.00353,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 20,
            swap_short: -74.7,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'NZDCAD',
                swf_icon: true,
                instrument: 'NZD/CAD',
            },
            contract_size: 100000,
            base_currency: 'NZD',
            minimum_size: 0.01,
            minimum_spread: 0.00008,
            target_spread: 0.00018,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: -0.97,
            swap_short: -2.71,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'NZDJPY',
                swf_icon: true,
                instrument: 'NZD/JPY',
            },
            contract_size: 100000,
            base_currency: 'NZD',
            minimum_size: 0.01,
            minimum_spread: 0.009,
            target_spread: 0.018,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 8.308,
            swap_short: -16.228,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'NZDUSD',
                swf_icon: true,
                instrument: 'NZD/USD',
            },
            contract_size: 100000,
            base_currency: 'NZD',
            minimum_size: 0.01,
            minimum_spread: 0.00005,
            target_spread: 0.00009,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'USDCNH',
                swf_icon: true,
                instrument: 'USD/CNH',
            },
            contract_size: 100000,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 0.0009,
            target_spread: 0.0011,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 17.34,
            swap_short: -71.24,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'USDMXN',
                swf_icon: true,
                instrument: 'USD/MXN',
            },
            contract_size: 100000,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 0.001,
            target_spread: 0.00248,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: -445.6,
            swap_short: 331.5,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'USDNOK',
                swf_icon: true,
                instrument: 'USD/NOK',
            },
            contract_size: 100000,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 0.00056,
            target_spread: 0.00368,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 19.93,
            swap_short: -78.3,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'USDPLN',
                swf_icon: true,
                instrument: 'USD/PLN',
            },
            contract_size: 100000,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 0.00049,
            target_spread: 0.00129,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: -40.681,
            swap_short: 12.641,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'USDSEK',
                swf_icon: true,
                instrument: 'USD/SEK',
            },
            contract_size: 100000,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 0.00114,
            target_spread: 0.00377,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 37.317,
            swap_short: -90.722,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'USDZAR',
                swf_icon: true,
                instrument: 'USD/ZAR',
            },
            contract_size: 100000,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 0.00185,
            target_spread: 0.00602,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: -236.984,
            swap_short: 80.9,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'AUDCAD',
                instrument: 'AUD/CAD micro',
            },
            contract_size: 1000,
            base_currency: 'AUD',
            minimum_size: 0.1,
            minimum_spread: 0.0001,
            target_spread: 0.00016,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'AUDCHF',
                instrument: 'AUD/CHF micro',
            },
            contract_size: 1000,
            base_currency: 'AUD',
            minimum_size: 0.1,
            minimum_spread: 0.00008,
            target_spread: 0.00013,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'AUDJPY',
                instrument: 'AUD/JPY micro',
            },
            contract_size: 1000,
            base_currency: 'AUD',
            minimum_size: 0.1,
            minimum_spread: 0.011,
            target_spread: 0.015,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'AUDNZD',
                instrument: 'AUD/NZD micro',
            },
            contract_size: 1000,
            base_currency: 'AUD',
            minimum_size: 0.1,
            minimum_spread: 0.00011,
            target_spread: 0.00021,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'AUDUSD',
                instrument: 'AUD/USD micro',
            },
            contract_size: 1000,
            base_currency: 'AUD',
            minimum_size: 0.1,
            minimum_spread: 0.00005,
            target_spread: 0.00006,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'EURAUD',
                instrument: 'EUR/AUD micro',
            },
            contract_size: 1000,
            base_currency: 'EUR',
            minimum_size: 0.1,
            minimum_spread: 0.00008,
            target_spread: 0.00017,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'EURCAD',
                instrument: 'EUR/CAD micro',
            },
            contract_size: 1000,
            base_currency: 'EUR',
            minimum_size: 0.1,
            minimum_spread: 0.00009,
            target_spread: 0.00019,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'EURCHF',
                instrument: 'EUR/CHF micro',
            },
            contract_size: 1000,
            base_currency: 'EUR',
            minimum_size: 0.1,
            minimum_spread: 0.00012,
            target_spread: 0.00015,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'EURGBP',
                instrument: 'EUR/GBP micro',
            },
            contract_size: 1000,
            base_currency: 'EUR',
            minimum_size: 0.1,
            minimum_spread: 0.00007,
            target_spread: 0.00009,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'EURJPY',
                instrument: 'EUR/JPY micro',
            },
            contract_size: 1000,
            base_currency: 'EUR',
            minimum_size: 0.1,
            minimum_spread: 0.008,
            target_spread: 0.013,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'EURNZD',
                instrument: 'EUR/NZD micro',
            },
            contract_size: 1000,
            base_currency: 'EUR',
            minimum_size: 0.1,
            minimum_spread: 0.00008,
            target_spread: 0.00024,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'EURUSD',
                instrument: 'EUR/USD micro',
            },
            contract_size: 1000,
            base_currency: 'EUR',
            minimum_size: 0.1,
            minimum_spread: 0.00006,
            target_spread: 0.00007,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'GBPCHF',
                instrument: 'GBP/CHF micro',
            },
            contract_size: 1000,
            base_currency: 'GBP',
            minimum_size: 0.1,
            minimum_spread: 0.00009,
            target_spread: 0.0002,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'GBPJPY',
                instrument: 'GBP/JPY micro',
            },
            contract_size: 1000,
            base_currency: 'GBP',
            minimum_size: 0.1,
            minimum_spread: 0.007,
            target_spread: 0.016,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'GBPUSD',
                instrument: 'GBP/USD micro',
            },
            contract_size: 1000,
            base_currency: 'GBP',
            minimum_size: 0.1,
            minimum_spread: 0.00007,
            target_spread: 0.00011,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'NZDUSD',
                instrument: 'NZD/USD micro',
            },
            contract_size: 1000,
            base_currency: 'NZD',
            minimum_size: 0.1,
            minimum_spread: 0.00005,
            target_spread: 0.00009,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'USDCAD',
                instrument: 'USD/CAD micro',
            },
            contract_size: 1000,
            base_currency: 'USD',
            minimum_size: 0.1,
            minimum_spread: 0.00007,
            target_spread: 0.00012,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'USDCHF',
                instrument: 'USD/CHF micro',
            },
            contract_size: 1000,
            base_currency: 'USD',
            minimum_size: 0.1,
            minimum_spread: 0.00005,
            target_spread: 0.00009,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'USDJPY',
                instrument: 'USD/JPY micro',
            },
            contract_size: 1000,
            base_currency: 'USD',
            minimum_size: 0.1,
            minimum_spread: 0.006,
            target_spread: 0.011,
            max_effective_leverage: 1000,
            margin_requirement: 0.1,
            swap_long: 0,
            swap_short: 0,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
    ],
    eu_data: [
        {
            instrument: {
                symbol: 'AUDCAD',
                instrument: 'AUD/CAD',
            },
            contract_size: 100000,
            base_currency: 'AUD',
            minimum_size: 0.01,
            minimum_spread: 0.0001,
            target_spread: 0.00016,
            max_effective_leverage: 20,
            margin_requirement: 5.0,
            swap_long: -3.83,
            swap_short: 0.12,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'AUDCHF',
                instrument: 'AUD/CHF',
            },
            contract_size: 100000,
            base_currency: 'AUD',
            minimum_size: 0.01,
            minimum_spread: 0.00008,
            target_spread: 0.00013,
            max_effective_leverage: 20,
            margin_requirement: 5.0,
            swap_long: 2.14,
            swap_short: -6.52,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'AUDJPY',
                instrument: 'AUD/JPY',
            },
            contract_size: 100000,
            base_currency: 'AUD',
            minimum_size: 0.01,
            minimum_spread: 0.01,
            target_spread: 0.014,
            max_effective_leverage: 20,
            margin_requirement: 5.0,
            swap_long: 7.33,
            swap_short: -10.69,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'AUDNZD',
                instrument: 'AUD/NZD',
            },
            contract_size: 100000,
            base_currency: 'AUD',
            minimum_size: 0.01,
            minimum_spread: 0.00014,
            target_spread: 0.00024,
            max_effective_leverage: 20,
            margin_requirement: 5.0,
            swap_long: -4.55,
            swap_short: 0.78,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'AUDUSD',
                instrument: 'AUD/USD',
            },
            contract_size: 100000,
            base_currency: 'AUD',
            minimum_size: 0.01,
            minimum_spread: 0.00003,
            target_spread: 0.00004,
            max_effective_leverage: 20,
            margin_requirement: 5.0,
            swap_long: -3.57,
            swap_short: 0.33,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'CADCHF',
                instrument: 'CAD/CHF',
            },
            contract_size: 100000,
            base_currency: 'CAD',
            minimum_size: 0.01,
            minimum_spread: 0.00008,
            target_spread: 0.00013,
            max_effective_leverage: 20,
            margin_requirement: 5.0,
            swap_long: 3.15,
            swap_short: -8.62,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'CADJPY',
                instrument: 'CAD/JPY',
            },
            contract_size: 100000,
            base_currency: 'CAD',
            minimum_size: 0.01,
            minimum_spread: 0.01,
            target_spread: 0.017,
            max_effective_leverage: 20,
            margin_requirement: 5.0,
            swap_long: 12.74,
            swap_short: -15.02,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'CHFJPY',
                instrument: 'CHF/JPY',
            },
            contract_size: 100000,
            base_currency: 'CHF',
            minimum_size: 0.01,
            minimum_spread: 0.01,
            target_spread: 0.021,
            max_effective_leverage: 30,
            margin_requirement: 3.33,
            swap_long: -0.02,
            swap_short: -5.8,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'EURAUD',
                instrument: 'EUR/AUD',
            },
            contract_size: 100000,
            base_currency: 'EUR',
            minimum_size: 0.01,
            minimum_spread: 0.00006,
            target_spread: 0.00015,
            max_effective_leverage: 20,
            margin_requirement: 5.0,
            swap_long: -7.4,
            swap_short: 1,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'EURCAD',
                instrument: 'EUR/CAD',
            },
            contract_size: 100000,
            base_currency: 'EUR',
            minimum_size: 0.01,
            minimum_spread: 0.00006,
            target_spread: 0.00016,
            max_effective_leverage: 30,
            margin_requirement: 3.33,
            swap_long: -9.76,
            swap_short: 3.97,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'EURCHF',
                instrument: 'EUR/CHF',
            },
            contract_size: 100000,
            base_currency: 'EUR',
            minimum_size: 0.01,
            minimum_spread: 0.00003,
            target_spread: 0.00006,
            max_effective_leverage: 30,
            margin_requirement: 3.33,
            swap_long: 1.84,
            swap_short: -7.66,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'EURGBP',
                instrument: 'EUR/GBP',
            },
            contract_size: 100000,
            base_currency: 'EUR',
            minimum_size: 0.01,
            minimum_spread: 0.00003,
            target_spread: 0.00005,
            max_effective_leverage: 30,
            margin_requirement: 3.33,
            swap_long: -5.21,
            swap_short: 1.49,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'EURJPY',
                instrument: 'EUR/JPY',
            },
            contract_size: 100000,
            base_currency: 'EUR',
            minimum_size: 0.01,
            minimum_spread: 0.005,
            target_spread: 0.01,
            max_effective_leverage: 30,
            margin_requirement: 3.33,
            swap_long: 8.5,
            swap_short: -13.55,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'EURNOK',
                instrument: 'EUR/NOK',
            },
            contract_size: 100000,
            base_currency: 'EUR',
            minimum_size: 0.01,
            minimum_spread: 0.00101,
            target_spread: 0.0044,
            max_effective_leverage: 20,
            margin_requirement: 5.0,
            swap_long: -18.65,
            swap_short: -18.4,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'EURNZD',
                instrument: 'EUR/NZD',
            },
            contract_size: 100000,
            base_currency: 'EUR',
            minimum_size: 0.01,
            minimum_spread: 0.0001,
            target_spread: 0.00026,
            max_effective_leverage: 20,
            margin_requirement: 5.0,
            swap_long: -15.397,
            swap_short: 5.287,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'EURPLN',
                instrument: 'EUR/PLN',
            },
            contract_size: 100000,
            base_currency: 'EUR',
            minimum_size: 0.01,
            minimum_spread: 0.00058,
            target_spread: 0.00125,
            max_effective_leverage: 20,
            margin_requirement: 5.0,
            swap_long: -70.36,
            swap_short: 43.55,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'EURSEK',
                instrument: 'EUR/SEK',
            },
            contract_size: 100000,
            base_currency: 'EUR',
            minimum_size: 0.01,
            minimum_spread: 0.0009,
            target_spread: 0.00366,
            max_effective_leverage: 20,
            margin_requirement: 5.0,
            swap_long: -42.307,
            swap_short: -29.695,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'EURUSD',
                instrument: 'EUR/USD',
            },
            contract_size: 100000,
            base_currency: 'EUR',
            minimum_size: 0.01,
            minimum_spread: 0.00004,
            target_spread: 0.00005,
            max_effective_leverage: 30,
            margin_requirement: 3.33,
            swap_long: -7.82,
            swap_short: 4.15,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'GBPAUD',
                instrument: 'GBP/AUD',
            },
            contract_size: 100000,
            base_currency: 'GBP',
            minimum_size: 0.01,
            minimum_spread: 0.00005,
            target_spread: 0.00021,
            max_effective_leverage: 20,
            margin_requirement: 5.0,
            swap_long: -0.96,
            swap_short: -5.95,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'GBPCAD',
                instrument: 'GBP/CAD',
            },
            contract_size: 100000,
            base_currency: 'GBP',
            minimum_size: 0.01,
            minimum_spread: 0.00013,
            target_spread: 0.00027,
            max_effective_leverage: 20,
            margin_requirement: 5.0,
            swap_long: -5.21,
            swap_short: -1.53,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'GBPCHF',
                instrument: 'GBP/CHF',
            },
            contract_size: 100000,
            base_currency: 'GBP',
            minimum_size: 0.01,
            minimum_spread: 0.0001,
            target_spread: 0.00021,
            max_effective_leverage: 30,
            margin_requirement: 3.33,
            swap_long: 4.59,
            swap_short: -13.57,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'GBPJPY',
                instrument: 'GBP/JPY',
            },
            contract_size: 100000,
            base_currency: 'GBP',
            minimum_size: 0.01,
            minimum_spread: 0.005,
            target_spread: 0.014,
            max_effective_leverage: 30,
            margin_requirement: 3.33,
            swap_long: 14.874,
            swap_short: -28.08,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'GBPNOK',
                instrument: 'GBP/NOK',
            },
            contract_size: 100000,
            base_currency: 'GBP',
            minimum_size: 0.01,
            minimum_spread: 0.0012,
            target_spread: 0.00521,
            max_effective_leverage: 20,
            margin_requirement: 5.0,
            swap_long: 10.98,
            swap_short: -70.94,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'GBPNZD',
                instrument: 'GBP/NZD',
            },
            contract_size: 100000,
            base_currency: 'GBP',
            minimum_size: 0.01,
            minimum_spread: 0.00016,
            target_spread: 0.00039,
            max_effective_leverage: 20,
            margin_requirement: 5.0,
            swap_long: -12.671,
            swap_short: -3.03,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'GBPSEK',
                instrument: 'GBP/SEK',
            },
            contract_size: 100000,
            base_currency: 'GBP',
            minimum_size: 0.01,
            minimum_spread: 0.00121,
            target_spread: 0.00319,
            max_effective_leverage: 20,
            margin_requirement: 5.0,
            swap_long: 20,
            swap_short: -74.7,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'GBPUSD',
                instrument: 'GBP/USD',
            },
            contract_size: 100000,
            base_currency: 'GBP',
            minimum_size: 0.01,
            minimum_spread: 0.00004,
            target_spread: 0.00008,
            max_effective_leverage: 30,
            margin_requirement: 3.33,
            swap_long: -4.64,
            swap_short: -0.2,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'NZDCAD',
                instrument: 'NZD/CAD',
            },
            contract_size: 100000,
            base_currency: 'NZD',
            minimum_size: 0.01,
            minimum_spread: 0.0001,
            target_spread: 0.0002,
            max_effective_leverage: 20,
            margin_requirement: 5.0,
            swap_long: -0.97,
            swap_short: -2.71,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'NZDJPY',
                instrument: 'NZD/JPY',
            },
            contract_size: 100000,
            base_currency: 'NZD',
            minimum_size: 0.01,
            minimum_spread: 0.009,
            target_spread: 0.018,
            max_effective_leverage: 20,
            margin_requirement: 5.0,
            swap_long: 8.308,
            swap_short: -16.228,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'NZDUSD',
                instrument: 'NZD/USD',
            },
            contract_size: 100000,
            base_currency: 'NZD',
            minimum_size: 0.01,
            minimum_spread: 0.00006,
            target_spread: 0.0001,
            max_effective_leverage: 20,
            margin_requirement: 5.0,
            swap_long: -0.69,
            swap_short: -1.05,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'USDCAD',
                instrument: 'USD/CAD',
            },
            contract_size: 100000,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 0.00006,
            target_spread: 0.00011,
            max_effective_leverage: 30,
            margin_requirement: 3.33,
            swap_long: -0.6,
            swap_short: -3.6,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'USDCHF',
                instrument: 'USD/CHF',
            },
            contract_size: 100000,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 0.00003,
            target_spread: 0.00007,
            max_effective_leverage: 30,
            margin_requirement: 3.33,
            swap_long: 4.97,
            swap_short: -12.42,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'USDCNH',
                instrument: 'USD/CNH',
            },
            contract_size: 100000,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 0.0009,
            target_spread: 0.0011,
            max_effective_leverage: 20,
            margin_requirement: 5.0,
            swap_long: 17.34,
            swap_short: -71.24,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'USDJPY',
                instrument: 'USD/JPY',
            },
            contract_size: 100000,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 0.004,
            target_spread: 0.009,
            max_effective_leverage: 30,
            margin_requirement: 3.33,
            swap_long: 18.4,
            swap_short: -22.2,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'USDMXN',
                instrument: 'USD/MXN',
            },
            contract_size: 100000,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 0.0012,
            target_spread: 0.00268,
            max_effective_leverage: 20,
            margin_requirement: 5.0,
            swap_long: -445.6,
            swap_short: 331.5,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'USDNOK',
                instrument: 'USD/NOK',
            },
            contract_size: 100000,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 0.0009,
            target_spread: 0.00402,
            max_effective_leverage: 20,
            margin_requirement: 5.0,
            swap_long: 19.93,
            swap_short: -78.3,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'USDPLN',
                instrument: 'USD/PLN',
            },
            contract_size: 100000,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 0.00049,
            target_spread: 0.00129,
            max_effective_leverage: 20,
            margin_requirement: 5.0,
            swap_long: -40.681,
            swap_short: 12.641,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'USDSEK',
                instrument: 'USD/SEK',
            },
            contract_size: 100000,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 0.00114,
            target_spread: 0.00377,
            max_effective_leverage: 20,
            margin_requirement: 5.0,
            swap_long: 37.317,
            swap_short: -90.722,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
        {
            instrument: {
                symbol: 'USDZAR',
                instrument: 'USD/ZAR',
            },
            contract_size: 100000,
            base_currency: 'USD',
            minimum_size: 0.01,
            minimum_spread: 0.0015,
            target_spread: 0.00567,
            max_effective_leverage: 20,
            margin_requirement: 5.0,
            swap_long: -236.984,
            swap_short: 80.9,
            trading_hours: 'Sun 21:05 - Friday 20:55 GMT',
        },
    ],
}

export default forex_specification
